import React, { useState, useLayoutEffect, useEffect } from "react"
import { Link } from "gatsby"
import { StaticQuery, useStaticQuery, graphql } from "gatsby"
import "../styles/icon-halo.css"
import "../styles/campaign-homepage.css"
import PlusSign from "../img/plus-sign.svg"

export default function Professions(props) {
  const [icons, getIcons] = useState(
    useStaticQuery(
      graphql`
        query Icons {
          allButterPage(
            filter: { page_type: { eq: "product_page_01_20_2022" } }
          ) {
            nodes {
              slug
              industry_page_hero {
                fields {
                  thumbnail
                  Description
                  hero_headline
                  title
                  hero_image
                  hero_paragraph
                  image
                }
              }
            }
          }
        }
      `
    )
  )

  const [customIcons, setCustomIcons] = useState(null)

  useLayoutEffect(() => {
    // var icon_array = icons.allButterPage.nodes
    // for (var i=0; i-1 < document.getElementsByClassName('bullseye').length; i++) {
    // for (var i = 0; i < 11; i++) {
    //   let current_el = document.getElementsByClassName("bullseye")[i].id
    //   console.log("current_el: ", current_el)
    //   let obj = icon_array.filter(o => {
    //     return o.slug == current_el
    //   })
    //   try {
    //     let current = obj[0]
    //     console.log(current.thumbnail)
    //     document.getElementsByClassName("bullseye")[
    //       i
    //     ].innerHTML = `<img src=${current.thumbnail} alt=${current.slug}+" icon" />`
    //   } catch (err) {
    //     console.log(err.message)
    //   }
    // }
  }, [])

  useEffect(() => {
    for (var i = 0; i < 12; i++) {
      let current_el = document.getElementsByClassName("bullseye")[i].id
      if (customIcons) {
        const obj = customIcons[current_el]
        try {
          if (obj) {
            console.log(obj.thumbnail)
            document.getElementsByClassName("bullseye")[
              i
            ].innerHTML = `<img src=${obj.thumbnail} alt=${obj.slug}+" icon" />`
          } else {
            console.log("Not found: ", current_el)
          }
        } catch (err) {
          console.log(err.message)
        }
      }
    }
  }, [customIcons])

  useEffect(() => {
    const iconsObj = {}
    const iconsArray = icons?.allButterPage?.nodes
    for (let index = 0; index < iconsArray?.length || 0; index++) {
      const element = iconsArray[index]
      let objectToSet = { slug: element?.slug, thumbnail: "" }
      for (
        let index = 0;
        index < element?.industry_page_hero?.length || 0;
        index++
      ) {
        const heroData = element?.industry_page_hero[index]
        //console.log("element?.slug: ", element?.slug)
        //console.log("heroData: ", heroData)
        if (heroData?.fields?.thumbnail) {
          objectToSet.thumbnail = heroData?.fields?.thumbnail
        }
      }
      iconsObj[element?.slug] = objectToSet
    }
    setCustomIcons(iconsObj)
  }, [icons])

  return (
    <div id="professions">
      <h2>A Few of the Businesses We Insure</h2>
      <div className="section">
        <div className="col grid_1_of_4">
          <Link to="/building-contractor-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div
                    className="bullseye"
                    id="building-contractor-insurance"
                  ></div>
                </div>
              </div>
            </div>
            <h4>Building Contractors</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/primary-liability">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="primary-liability"></div>
                </div>
              </div>
            </div>
            <h4>Trucking Businesses</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/landscaping-and-lawn-care-business-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div
                    className="bullseye"
                    id="landscaping-and-lawn-care-business-insurance"
                  ></div>
                </div>
              </div>
            </div>
            <h4>Landscapers</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/insurance-for-attorneys">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="insurance-for-attorneys"></div>
                </div>
              </div>
            </div>
            <h4>Attorneys</h4>
          </Link>
        </div>
      </div>

      <div className="section">
        <div className="col grid_1_of_4">
          <Link to="/manufacturing-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="manufacturing-insurance"></div>
                </div>
              </div>
            </div>
            <h4>Manufacturers</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/professional-liability-insurance-for-consultants">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div
                    className="bullseye"
                    id="professional-liability-insurance-for-consultants"
                  ></div>
                </div>
              </div>
            </div>
            <h4>Business Consultancies</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/it-contractors-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="it-contractors-insurance"></div>
                </div>
              </div>
            </div>
            <h4>IT Professionals</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/restaurant-and-bar-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="restaurant-and-bar-insurance"></div>
                </div>
              </div>
            </div>
            <h4>Restaurants &amp; Bars</h4>
          </Link>
        </div>
      </div>

      <div className="section">
        <div className="col grid_1_of_4">
          <Link to="/food-truck-and-caterer-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div
                    className="bullseye"
                    id="food-truck-and-caterer-insurance"
                  ></div>
                </div>
              </div>
            </div>
            <h4>Food Trucks &amp; Caterers</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/professional-liability-insurance-for-architects">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div
                    className="bullseye"
                    id="professional-liability-insurance-for-architects"
                  ></div>
                </div>
              </div>
            </div>
            <h4>Architects &amp; Engineers</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/auto-dealer-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="auto-dealer-insurance"></div>
                </div>
              </div>
            </div>
            <h4>Car Dealerships</h4>
          </Link>
        </div>
        <div className="col grid_1_of_4">
          <Link to="/tow-truck-insurance">
            <div className="profession-icon">
              <div className="halo">
                <div className="halo">
                  <div className="bullseye" id="tow-truck-insurance">
                  </div>
                </div>
              </div>
            </div>
            <h4>Tow Trucks</h4>
          </Link>
        </div>
      </div>
    </div>
  )
}
