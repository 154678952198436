import React from 'react';
import Differentiator from './Differentiator';
import Footer from './Footer';
import MultistepQuote from './MultistepQuote';
import ProcessGraphic from './ProcessGraphic';
import Professions from './Professions';
import Service from './Service';
import SiriusXm from './SiriusXm';
import  "../styles/321global.css"
import "../styles/nav.css"
import { StaticQuery, graphql } from "gatsby"
import ReactHtmlParser from 'react-html-parser';
import IndustriesBar from './ProfessionsScrollerv2';
import RateComparison from './RateComparison';
import '../styles/multistep-form.css'

export default function MediaHome (props)  {
return (
  <StaticQuery
    query = {graphql`
    {
      butterPage(slug: {eq: "index"}) {
        h1
        herosubhead
        buttontext
        processcomponentheading
        step1text
        step2text
        step3text
        differencesectionheading
        differencesectioncopy
        businesstypesheading
        servicesectionheading
        servicesectioncopy
      }
    }
  `}

    render = {(data,parser) =>
      (
      <div>
        <div id="media_hero_bg">
            <MultistepQuote  heroHeadline={ReactHtmlParser(data.butterPage.h1)} heroSubhead={ReactHtmlParser(data.butterPage.herosubhead)} buttonText={data.butterPage.buttontext}/>
        </div>
        <Differentiator></Differentiator>
        <Professions></Professions>
        <Service></Service>
        <Footer></Footer>
      </div>

    )}
  />)

}









