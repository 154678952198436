import React from 'react';
import logo from '../img/insurance321_logo_white.svg'
import RocketPhone from '../img/rocket-to-phone-white.gif'
import '../styles/nav.css'
import  "../styles/media-home.css"
import  "../styles/nomination.css"
import  "../styles/multistep-quote.css"
import  "../styles/hero-v4.css"
import Session321 from './Session321';
import BoxExpanderRight from '../components/BoxExpanderRight.js'
import MultiStepForm from './multistep-form/MultiStepForm';
import QuoteCarousel from './QuoteCarousel';


export default function QuoteAppHero(props) {
    
    return (
        <div className ="multistep-hero" id="media_hero">
            <Session321 />
            <div className="hero-top">
                <div id="logo"><img src={logo} alt="menu icon" /></div>
                <div className="call-conversion">
                    <div id="call_icon">
                        <h4>234-230-6321</h4>
                    </div>
                </div>
            </div>
            <div className="hero-bottom">
                <div id="hero_left">
                    <div className="medium-blue-overlay">
                        <div className="hero-text">
                        <div className="header"><h1>A Smarter Way to Buy<br /><span className='bold'>Business Insurance</span></h1></div>
                            {/* <QuoteComparison /> */}
                            {/* <div id="quote_image"><img src={QuoteAnimation} alt="quote animation" /></div> */}
                            <QuoteCarousel />
                            <div id="hero_subhead">
                                <h3>Insurance321 has helped over <span className='bold'>50,000</span> businesses save an average of <span className='bold'>29%</span> on insurance</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="hero_right">                    
                    <BoxExpanderRight />
                    <div id="multistep_form">
                        <MultiStepForm />
                    </div>
                </div>
            </div> 
        </div>
    )
}
