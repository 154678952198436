import React from 'react'
import building_icon from '../img/building.svg'
import operator_icon from '../img/operator_icon.svg'
import form_icon from '../img/form_icon.svg'
import logo  from '../img/logo.svg'
import launchpad_mark from '../img/launchpad-mark.svg'

const ProcessGraphic = () => {
    return (
        <div id="process_section">
            <h2>3 Steps to a Great Quote</h2>
            <div id ="process_container">
                <div id="step_1" className="step"><div className="inline circle">3</div><img src={launchpad_mark} /><p>Submit request to the LaunchPad Quote System</p></div>
                <div id="step_2" className="step"><div className="inline circle">2</div><img src={operator_icon} /><p>Confirm business details with our control center</p></div>
                <div id="step_3" className="step"><div className="inline circle">1</div><img src={logo} height="107px"/><p>Launch Your Quote</p></div>
            </div>
        </div> );
}

export default ProcessGraphic;
