import React from "react"
import MediaMultistep from "../components/MediaMultistep"
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'


export default function Home(data) {
  return (
    <div id="homepage">
        <div id="media_homepage">
            <Helmet>
              <title>{data.data.butterPage.pagetitle}</title>
              <meta name="description" content={data.data.butterPage.herosubhead}></meta>
            </Helmet>
            <MediaMultistep />
        </div>
    </div>)
}

export const query = graphql`
query 
{
  butterPage(slug: {eq: "index"}) {
      slug
      page_type
      pagetitle
      h1
      heroimage
      herosubhead
  }
}
`